import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

var prevnum = -1;

const BackgroundVideo = (props) => {
  const videos = props.videos;
  const LoginSignup = props?.LoginSignup;
  const [update, setUpdate] = useState(true);

  const randNumber = () => {
    var randnum;
    do {
      randnum = Math.floor(Math.random() * videos.length);
    } while (randnum === prevnum);
    prevnum = randnum;
    return randnum;
  };

  return (
    <ReactPlayer
      url={'https://lineage-assets.s3.us-east-2.amazonaws.com/' + videos[randNumber()].Key}
      className="pb-video"
      width="100%"
      height="100%"
      muted
      playing
      onEnded={() => setUpdate(!update)}
      id={LoginSignup ? 'pb-videoID' : ''}
    />
  );
};
export default BackgroundVideo;
